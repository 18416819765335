<template>
  <component :is="'b-card'">
    <b-tabs pills>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('market.general.title') }}</span>
        </template>
        <GeneralTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="MapPinIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('market.localization.title') }}</span>
        </template>
        <LocalizationTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Integrations</span>
        </template>
        <IntegrationTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="DollarSignIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('market.account_setting.commission.title') }}</span>
        </template>
        <Commissions class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'
import LocalizationTab from './LocalizationTab.vue'
import IntegrationTab from './IntegrationTab.vue'
import GeneralTab from './GeneralTab.vue'
import Commissions from './Commissions.vue'

export default {
  components: {
    IntegrationTab,
    BCard,
    BTab,
    BTabs,
    LocalizationTab,
    GeneralTab,
    Commissions,
  },
}
</script>
