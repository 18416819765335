<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon
              icon="MessageCircleIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">SMS</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="sms_login"
              >
                <b-form-input
                  id="sms_login"
                  v-model="data.sms_login"
                  :state="errors && errors.sms_login ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_login">
                  {{ errors.sms_login[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="sms_password"
              >
                <b-form-input
                  id="sms_password"
                  v-model="data.sms_password"
                  :state="errors && errors.sms_password ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_password">
                  {{ errors.sms_password[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.from.label')"
                label-for="sms_from"
              >
                <b-form-input
                  id="sms_from"
                  v-model="data.sms_from"
                  :state="errors && errors.sms_from ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.sms_from">
                  {{ errors.sms_from[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">WayForPay</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="wfp_account"
              >
                <b-form-input
                  id="wfp_account"
                  v-model="data.wfp_account"
                  :state="errors && errors.wfp_account ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.wfp_account">
                  {{ errors.wfp_account[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="wfp_secret"
              >
                <b-form-input
                  id="wfp_secret"
                  v-model="data.wfp_secret"
                  :state="errors && errors.wfp_secret ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.wfp_secret">
                  {{ errors.wfp_secret[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="CodesandboxIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Cryptomus</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="crpt_account"
              >
                <b-form-input
                  id="crpt_account"
                  v-model="data.crpt_account"
                  :state="errors && errors.crpt_account ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.crpt_account">
                  {{ errors.crpt_account[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="crpt_secret"
              >
                <b-form-input
                  id="crpt_secret"
                  v-model="data.crpt_secret"
                  :state="errors && errors.crpt_secret ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.crpt_secret">
                  {{ errors.crpt_secret[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="SendIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Telegram Bot</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.login.label')"
                label-for="tgbot_account"
              >
                <b-form-input
                  id="tgbot_account"
                  v-model="data.tgbot_account"
                  :state="errors && errors.tgbot_account ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.tgbot_account">
                  {{ errors.tgbot_account[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('form.password.label')"
                label-for="tgbot_secret"
              >
                <b-form-input
                  id="tgbot_secret"
                  v-model="data.tgbot_secret"
                  :state="errors && errors.tgbot_secret ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.tgbot_secret">
                  {{ errors.tgbot_secret[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <b-row
        v-if="$ability.can('update', 'admin:settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput, BTab, BTabs,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/admin/settings', { params: { group: 'integration' } }).then(response => {
      const {
        data: {
          wfp_account,
          wfp_secret,
          crpt_account,
          crpt_secret,
          tgbot_account,
          tgbot_secret,
          sms_login,
          sms_from,
          sms_password,
        },
      } = response

      this.data = {
        wfp_account,
        wfp_secret,
        crpt_account,
        crpt_secret,
        tgbot_account,
        tgbot_secret,
        sms_login,
        sms_from,
        sms_password,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/integration', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
