<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Скинути системний кеш на сайті"
            label-for="cache"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabled"
              @click="run"
            >
              Скинути
            </b-button>
            <b-form-invalid-feedback style="display: block;">
              Скидання триває 5-10 хв у фоновому режимі (декілька разів нажимати не треба) і оновлює скрипти та переклади. У цей час не проводити дії з адмінкою через ризик втрати вашої роботи. Рекомендовано скидати кеш не частіше 1 разу на добу. Бажано скидати у період найменшої активності користувачів.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'admin:settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      disabled: false,
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/admin/settings', { params: { group: 'general' } }).then(response => {
      const { data: { maintenance_mode } } = response

      this.data = {
        maintenance_mode,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    run() {
      this.confirm(() => {
        this.$http.get('/api/admin/cache')
          .then(() => {
            this.disabled = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.disabled = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: this.$t('notifications.confirm.run.clearCache'),
        text: null,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
