<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_system')"
            label-for="commission_system"
          >
            <b-form-input
              id="commission_system"
              v-model="data.commission_system"
              :state="errors && errors.commission_system ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_system">
              {{ errors.commission_system[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Commission User ID"
            label-for="author_id"
            :state="errors && errors.commission_user_id ? false : null"
          >
            <v-select
              id="author_id"
              v-model="data.commission_user_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="userOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="author_id"
              autocomplete="off"
              :searchable="true"
              @search="searchUser"
            />
            <b-form-invalid-feedback v-if="errors && errors.commission_user_id">
              {{ errors.user_id[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.amount_found_min')"
            label-for="amount_found_min"
          >
            <b-form-input
              id="amount_found_min"
              v-model="data.amount_found_min"
              :state="errors && errors.amount_found_min ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.amount_found_min">
              {{ errors.amount_found_min[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.amount_found_max')"
            label-for="amount_found_max"
          >
            <b-form-input
              id="amount_found_max"
              v-model="data.amount_found_max"
              :state="errors && errors.amount_found_max ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.amount_found_max">
              {{ errors.amount_found_max[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_found')"
            label-for="commission_found"
          >
            <b-form-input
              id="commission_found"
              v-model="data.commission_found"
              :state="errors && errors.commission_found ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_found">
              {{ errors.commission_found[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_found_min')"
            label-for="commission_found_min"
          >
            <b-form-input
              id="commission_found_min"
              v-model="data.commission_found_min"
              :state="errors && errors.commission_found_min ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_found_min">
              {{ errors.commission_found_min[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.amount_withdraw_min')"
            label-for="amount_withdraw_min"
          >
            <b-form-input
              id="amount_withdraw_min"
              v-model="data.amount_withdraw_min"
              :state="errors && errors.amount_withdraw_min ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.amount_withdraw_min">
              {{ errors.amount_withdraw_min[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.amount_withdraw_max')"
            label-for="amount_withdraw_max"
          >
            <b-form-input
              id="amount_withdraw_max"
              v-model="data.amount_withdraw_max"
              :state="errors && errors.amount_withdraw_max ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.amount_withdraw_max">
              {{ errors.amount_withdraw_max[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_withdraw')"
            label-for="commission_withdraw"
          >
            <b-form-input
              id="commission_withdraw"
              v-model="data.commission_withdraw"
              :state="errors && errors.commission_withdraw ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_withdraw">
              {{ errors.commission_withdraw[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_withdraw_min')"
            label-for="commission_withdraw_min"
          >
            <b-form-input
              id="commission_withdraw_min"
              v-model="data.commission_withdraw_min"
              :state="errors && errors.commission_withdraw_min ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_withdraw_min">
              {{ errors.commission_withdraw_min[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>
      <hr>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.commission_referral')"
            label-for="commission_referral"
          >
            <b-form-input
              id="commission_referral"
              v-model="data.commission_referral"
              :state="errors && errors.commission_referral ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.commission_referral">
              {{ errors.commission_referral[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.gift_referral')"
            label-for="gift_referral"
          >
            <b-form-input
              id="gift_referral"
              v-model="data.gift_referral"
              :state="errors && errors.gift_referral ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.gift_referral">
              {{ errors.gift_referral[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.transactions.gift_referer')"
            label-for="gift_referer"
          >
            <b-form-input
              id="gift_referer"
              v-model="data.gift_referer"
              :state="errors && errors.gift_referer ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.gift_referer">
              {{ errors.gift_referer[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <b-row
        v-if="$ability.can('update', 'admin:settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
  },
  data() {
    return {
      data: null,
      userOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/admin/settings', { params: { group: 'transactions' } }).then(response => {
      const {
        data: {
          commission_found,
          commission_found_min,
          commission_withdraw,
          commission_withdraw_min,
          commission_referral,
          commission_system,
          gift_referral,
          gift_referer,
          amount_found_min,
          amount_found_max,
          amount_withdraw_min,
          amount_withdraw_max,
          commission_user_id,
        },
      } = response

      this.data = {
        commission_found,
        commission_found_min,
        commission_withdraw,
        commission_withdraw_min,
        commission_referral,
        commission_system,
        gift_referral,
        gift_referer,
        amount_found_min,
        amount_found_max,
        amount_withdraw_min,
        amount_withdraw_max,
        commission_user_id,
      }
      this.userOptions.push({
        value: commission_user_id,
        label: commission_user_id,
      })
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/transactions', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
  },
}
</script>
